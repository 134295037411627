import React from 'react';
import * as DesignSystem from 'src/components/design-system';

import useForm from 'src/hooks/useForm';
import { isLoading } from 'src/hooks/axios/useAxios';

import ErrorAlert from 'src/components/design-system/alerts/error';
import FormFooter from 'src/components/design-system/form/footer';
import SimpleInput from 'src/components/design-system/form/input';
import FormLine from 'src/components/design-system/form/line';
import FormSection from 'src/components/design-system/form/section';
import Phone from 'src/components/design-system/form/phone';
import CancelButton from 'src/components/design-system/buttons/cancel';
import Submit from 'src/components/design-system/buttons/submit';

import routes from 'src/config/routes';

const EditFamille = ({ famille }) => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: famille,
    axiosConfig: {
      method: 'PUT',
      url: routes.userFamille(),
    },
    redirectUrl: `/famille/`,
  });

  const updateTextField = (fieldName) => (e) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };
  const errors =
    requestState.status === 'error' ? requestState.error.errors : {};

  return (
    <form className="w-full" onSubmit={submitForm}>
      {requestState.status === 'error' && (
        <ErrorAlert
          className="mt-4"
          message="Un ou plusieurs champs ne sont pas correctement remplis"
        />
      )}
      <DesignSystem.Card className="mt-4" title="Édition du profil">
        <FormSection>Parent 1</FormSection>
        <FormLine label="Prénom" htmlFor="parent1_prenom">
          <SimpleInput
            id="parent1_prenom"
            value={formData.parent1_prenom}
            onChange={updateTextField('parent1_prenom')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Nom" htmlFor="parent1_nom">
          <SimpleInput
            id="parent1_nom"
            value={formData.parent1_nom}
            onChange={updateTextField('parent1_nom')}
            errors={errors}
          />
        </FormLine>
        <FormSection>Parent 2</FormSection>
        <FormLine label="Prénom" htmlFor="parent2_prenom">
          <SimpleInput
            id="parent2_prenom"
            value={formData.parent2_prenom}
            onChange={updateTextField('parent2_prenom')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Nom" htmlFor="parent2_nom">
          <SimpleInput
            id="parent2_nom"
            value={formData.parent2_nom}
            onChange={updateTextField('parent2_nom')}
            errors={errors}
          />
        </FormLine>
        <FormSection>Contact</FormSection>
        <FormLine
          label="E-mail principal"
          help="Cet e-mail sert aussi à l'identification des adhérents sur le site web"
          htmlFor="mail_principal"
        >
          <SimpleInput
            id="mail_principal"
            value={formData.mail_principal}
            onChange={updateTextField('mail_principal')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="E-mail secondaire" htmlFor="mail_secondaire">
          <SimpleInput
            id="mail_secondaire"
            value={formData.mail_secondaire}
            onChange={updateTextField('mail_secondaire')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Téléphone principal" htmlFor="tel_principal">
          <Phone
            id="tel_principal"
            value={formData.tel_principal}
            onChange={updateTextField('tel_principal')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Téléphone secondaire" htmlFor="tel_secondaire">
          <Phone
            id="tel_secondaire"
            value={formData.tel_secondaire}
            onChange={updateTextField('tel_secondaire')}
            errors={errors}
          />
        </FormLine>
        <FormSection>Adresse</FormSection>
        <FormLine label="Adresse" help="" htmlFor="adresse">
          <SimpleInput
            id="adresse"
            value={formData.adresse}
            onChange={updateTextField('adresse')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Code postal" help="" htmlFor="code_postal">
          <SimpleInput
            id="code_postal"
            value={formData.code_postal}
            onChange={updateTextField('code_postal')}
            errors={errors}
          />
        </FormLine>
        <FormLine label="Ville" help="" htmlFor="ville">
          <SimpleInput
            id="ville"
            value={formData.ville}
            onChange={updateTextField('ville')}
            errors={errors}
          />
        </FormLine>
        <FormFooter>
          <CancelButton to={`/famille/`}>Retour à l'accueil</CancelButton>
          <Submit
            type="submit"
            disabled={isFormPristine}
            isLoading={isLoading(requestState)}
          >
            Mettre à jour
          </Submit>
        </FormFooter>
      </DesignSystem.Card>
    </form>
  );
};

export default EditFamille;
