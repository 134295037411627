import React from 'react';
import EditFamille from 'src/components/famille/edit';
import { withGuard } from 'src/components/admin/guard';
import FamilleLayout from 'src/components/layouts/famille';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

import Loading from 'src/components/design-system/loading';

import SEO from 'src/components/seo';

import routes from 'src/config/routes';

const EditPage = ({ userProfile }) => {
  const { requestState } = useAxiosNow({
    url: routes.userFamille(),
  });

  return (
    <FamilleLayout userProfile={userProfile}>
      <SEO title="Espace adhérent - édition" />
      <Loading requestState={requestState}>
        {(rs) => <EditFamille famille={rs.data.data} />}
      </Loading>
    </FamilleLayout>
  );
};

export default withGuard(EditPage);
